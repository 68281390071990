import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';

class Soft_Box_Tab extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imgattr = 'image'

        return <div>
            <div className="sbs-provide-security sbs-provide-security-bg pd-top-120 min-height-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-6 offset-xl-1 order-lg-12 wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                            <div className="img-with-video">
                                <div className="img-wrap">
                                    <img className="image-cel" src={publicUrl + sectiondata.softboxtab.imgurl} alt={imgattr} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 order-lg-1 align-self-center wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                            <div className="section-title style-two pd-left-10">
                                <h2 className="title">{sectiondata.softboxtab.sectiontitle}<span>{sectiondata.softboxtab.sectiontitle_color}</span> </h2>
                                <p className="text-justify">
                                Solo decídete a dar dos pasos adelante de tu competencia, nosotros te acompañamos en el proceso. Recuerda, no importa cuál sea tu negocio,
                                 siempre hay áreas de mejora, siempre puedes dar ese plus a tus clientes. Nuestro enfoque es claro y definido, llevar tu idea a una 
                                 App Móvil para iOS o Android, cosas como vender desde tu propia App o dar seguimiento (geolocalización) a los colaboradores de tu 
                                 empresa en sus rutas de entrega son solo algunas de las miles de cosas que podemos hacer para ti y para tu negocio. 
                                </p>
                                {/* <p className="text-justify">
                                Por ahora no nos enfocamos a crear juegos, nos gusta ser sinceros, para todo lo demás solo márcanos, estaremos felices de 
                                escucharte y que evalúes si somos los indicados para tu proyecto.  
                                </p> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Soft_Box_Tab
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './components/home';

class Root extends Component {
    render() {
        return (
            <Router>
                <BrowserRouter basename="/">
                    <div>
                        <Switch>
                            <Route exact path="/" component={Home} />
                        </Switch>
                    </div>
                </BrowserRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('traffics-body'));

import React, { Component } from 'react';

class Contact_Form extends Component {

    render() {
        let anchor = '#'
        let imgalt = 'image'
        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div>
            <div className="contact-form-area pd-top-112">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center w-100">
                                <h2 className="title">Contacto</h2>
                                <p>Sabemos que no podemos hacer todo tipo de proyectos, por eso no aceptamos todos, pero en cada proyecto que aceptamos dejamos nuestro 110%.
                                Si estás buscando quien te pueda ayudar a llevar tu idea a la realidad escríbenos, en caso de no ser los más indicados te lo haremos saber.
                                     Pero si lo somos te aseguramos, <strong>no te vas a arrepentir</strong>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5">
                            <img src={publicUrl + 'assets/img/others/22.jpg'} alt={imgalt} />
                        </div>
                        <div className="col-xl-4 col-lg-5 col-xs-2">
                            <div className="share-button mt-15">
                                <a className="share-button share-button-whatsapp" href="https://wa.link/iaxr4y/" target="_blank">
                                    <i className="fab fa-whatsapp"></i>
                                    <span>Contáctanos</span>
                                </a>
                            </div>
                        </div>

                        {/*<div className="col-lg-7 offset-xl-1">
                            <form className="riyaqas-form-wrap mt-5 mt-lg-0">
                                <div className="row custom-gutters-16">
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" id="first_name_contact"/>
                                            <label>Su nombre *</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" id="email_contact"/>
                                            <label>E-mail *</label>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" id="name_company"/>
                                            <label>Empresa</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" id="company_position"/>
                                            <label>Cargo</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <textarea className="single-input textarea" cols="20" id="message_contact"></textarea>
                                            <label className="single-input-label">Su Mensaje *</label>
                                        </div>
                                    </div> 
                                    

                                    <div id="mensaje-succes-contact">
                                        
                                    </div>



                                    <div className="col-12">
                                        <a className="btn btn-red mt-0" id="form-send" href={anchor}>Enviar</a>
                                    </div> 
                                </div>
                            </form>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Contact_Form


import React from 'react';
import Navbar from './global-components/navbar';
import Slider from './section-components/slider';
import SoftBoxAbout from './section-components/soft-box-about';
import Services from './section-components/services';
import TalentedPeople from './section-components/talented-people';
import SoftBoxTab from './section-components/soft-box-tab';
import SoftBoxLeftsideImage from './section-components/soft-box-leftside-image';
import Video from './section-components/video';
import Testimonial from './section-components/testimonial';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import ServicesWork from './section-components/services-work';
import ContactForm from './section-components/contact-form';


const Home = () => {
    return <div>
        <Navbar />
        <Slider />
        <Services />
        <SoftBoxAbout />
        <SoftBoxTab />
        <ServicesWork />
        <Testimonial />
        <ContactForm />
        <Footer />
    </div>
}

export default Home

